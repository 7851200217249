<template>
  <div class="all-services">
    <AdminTable title="All Services" :list="serviceList" :fields="fields" :loading="loading"
    @update-list="update" @edit="editService"/>
  </div>
</template>

<script>
import AdminTable from '@/components/admin/AdminTable'
import { mapState } from 'vuex'

import { additionalService } from '@/services/admin'

export default {
  name: 'AdminAllServices',
  components: {
    AdminTable,
  },
  data() {
    return {
      serviceList: [],
      loading: false,
    }
  },
  computed: {
    fields() {
      return [
        { key: 'id', label: 'ID' },
        { key: 'name', label: 'Name' },
        { key: 'description', label: 'Description' },
        { key: 'price', label: 'Price' },
        { key: 'isEnabled', label: 'Enabled', type: 'boolean' },
        { key: 'isRecurrent', label: 'Recurring', type: 'boolean' },
        { key: 'actions', label: 'Actions', type: 'actions', actions: ['edit'] },
      ]
    }
  },
  async beforeMount() {
    await this.update()
  },
  methods: {
    editService(service) {
      this.$store.commit('adminServiceStore/setService', service)
      this.$router.push({ name: 'service-edit', query: { id: service.id }})
    },
    async update() {
      this.loading = true
      this.serviceList = await additionalService.getServices()
      this.loading = false
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
